// Core variables and mixins
@import "variables";
@import "bootstrap";
@import "mixins";
@import "fonts";
@import "core";
@import "plugins";
// https://fontawesome.bootstrapcheatsheets.com/
@import "font-awesome/font-awesome";

body {
    background-color: #fff;
}


/*= input focus effects css
=========================== */
:focus {
    outline: none;
}




::-moz-selection { 
    background: $main-text;
  
    color: #fff; 
    text-shadow: none; 
}

::selection { 
    background: $main-text;
  
    color: #fff; 
    text-shadow: none;  }

section {
    width: 100%;
    float: left;
}




// Nav
.auto-hide-header {
    position: relative;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 0;
    @include clearfix;


    .container {
        position: relative;
    }

    .logo {
        float: left;
        display: inline-block;
        margin-top: 20px;
        font-size: 30px;
        @media(min-width:$screen-md-min){
            margin-top: 20px;
            font-size: 40px;
        }

        a {
            display: block;
            height: 40px;
            width: auto;
            
            @media(min-width:$screen-md-min){
                height: 60px;
            }
        }

        .icon-logo {
            height: 40px;
            width: 300px;
           
            display: inline-block;
            background-image: url('../img/logo.png');
            background-size: auto 40px;
            background-position: left center;
            background-repeat: no-repeat;
            @media(min-width:$screen-md-min){
                font-size: 60px;
                height: 60px;
                width: 400px;
                background-size: auto 60px ;
            }
        }
        .logo-img {
     
            text-indent: -9999px;
            display: none;
            
           

        }

    }

    .collapse {
        position: relative;
    }

    .primary-nav {
        display: inline-block;
        float: right;
        height: 100%;
        padding-right: 0;
        width: 100%;
        @media(min-width:$screen-md-min){
            width: auto;
        }

        .nav-trigger {
            position: absolute;
            top: 15px;
            display: table;
            height: auto;
            padding: 0 1em;
            font-size: 10px;
            text-transform: uppercase;
            color: $theme-primary;
            font-weight: bold;
            right: 10px;
            //border-left: 1px solid shade($color-4, 5%);

            &:hover, &:focus, &.active {
                text-decoration: none;
            }

            span {

                /* vertically align inside parent element */
                display: table-cell;
                vertical-align: middle;
            }

            em, em::after, em::before {

                /* this is the menu icon */
                display: block;
                position: relative;
                height: 2px;
                width: 22px;
                background-color: $theme-primary;
                backface-visibility: hidden;
            }

            em {

                /* this is the menu central line */
                margin: 10px auto;
                transition: background-color .2s;
                background-color: rgba(red, 0);
            }

            em::before, em::after {
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            em::before {

                /* this is the menu icon top line */
                
                transform: rotate(-45deg);
                @include rotate(-45deg);

            }

            em::after {

                /* this is the menu icon bottom line */
                
                transform: rotate(45deg);
                @include rotate(45deg);
            }

            @media(min-width:$screen-md-min){
                display: none;
            }

        }



        .nav-trigger.collapsed {
            em {

                /* transform menu icon into a 'X' icon */
                
                background-color: $theme-primary;
            }

            em::before {

                /* rotate top line */
                transform: translateY(-6px);
                @include translateY(-6px);
            }

            em::after {

                /* rotate bottom line */
                transform: translateY(6px);
                @include translateY(6px);
            }

        }




        .navigation {
            z-index: 2;
            padding: 10px 0;
            margin: 0;
            font-family: $title-font;
            //display: none;
            list-style: none;
            li {
                margin: 0;
                padding: 0;
                &.current-menu-ancestor {
                    a {
                        color: $theme-primary;
                    }
                }
            }
            @media(min-width:$screen-md-min){
                padding: 0;
            }
            a {

                /* target primary-nav links */
                display: block;
                color: $theme-primary;
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;

                &:hover, &:focus, &.active {
                    color: $theme-secondary;
                    text-decoration: none;
                }

            }

        }
        .lang {
            position: absolute;
            top: 20px;
            right: 0;
            list-style: none;
            margin: 0;
            padding: 0;
            z-index: 999;
            li {
                margin: 0;
                padding: 0 0 0 10px;
                float: left;
                font-size: 11px;
                font-weight: normal;
                text-transform: uppercase;
            }
            a {
                color: $theme-primary;
                &:hover, &:focus, &.active {
                    color: $theme-secondary;
                    text-decoration: none;
                }
            }
        }



        
        
           

        

        @media(min-width:$screen-md-min){

            /* vertically align its content */
            display: table;

            .collapse {
                display: block;
            }
            .navigation {

                /* vertically align inside parent element */
                display: table-cell;
                vertical-align: middle;            /* reset mobile style */
                position: relative;
                width: auto;
                top: 20px;
                right: 0;
                padding: 0;
                @include clearfix;

                li {
                    display: inline-block;
                    float: left;
                    //margin-right: 1.5em;

                    &:last-of-type {
                        margin-right: 0;
                    }

                }

                a {
                    padding: 0 0 0 15px;
                    border: none;
                    line-height: 80px;
                }

            }
            .lang {

               


            }

        }

    }



    &.shrink {
        background: #fff;
        position: fixed;
 
        

    }

    .dropdown-menu {
        padding:  0;
        border: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: relative;
        width: 100%;
        @media(min-width:$screen-md-min) {
            margin-top: 0 !important;
            position: absolute;
            width: auto;
        }
        li {
            display: block;
            width: 100%;
            a {
                padding: 5px 10px;
                line-height: 1.3 !important;
                font-size: 14px;
                @media(min-width:$screen-md-min) {
                    padding: 5px 15px;
                    line-height: 1.3 !important;
                }
            }
        }
        li a:after {
            display: none;
        }
        li a:hover {
            background-color: $theme-primary;
            color: #fff !important;
        }
    }

    .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
        background-color: $theme-primary;
        color: #fff !important;

    }



}




// Slide principal
.slide-principal {
    
    @include clearfix;
    margin-top: 0;

   
    .slide {
        display: table;
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
        background-color: #eee;
    }


    .container {
        height: calc(100vh - 90px);
        position: relative;
        display: table;

    }

    .owl-dots .owl-dot span {
        border: 0;
    }
    .owl-nav .owl-prev {
        left: 15px;
        top: 45%;
        color: #fff;
    }
    .owl-nav .owl-next {
        right: 15px;
        top: 45%;
        color: #fff;
    }

    .slide-text {
        display: table-cell;
        vertical-align: middle;
        
        @media (min-width:$screen-sm-min) {
           
        }
        .content-text {
            text-align: center;
            margin-top: 0;
            margin-bottom: 100px;

            @media (min-width:$screen-sm-min) {
                width: 100%;
                
                float: left;
                margin-top: 0;
            }
            @media (min-width:$screen-md-min) {
                
            }
            
        }

        h2 {
            margin: 15px 0 30px 0;
            font-size: 30px;
            font-weight: bold;
             @media (min-width: $screen-md-min) {
                font-size: 40px;
                margin: 15px 0 30px 0;
            }

             @media (min-width: $screen-lg-min) {
                font-size: 50px;
                margin: 15px 0 60px 0;
            }
            em {
                display: block;
                font-size: 16px;
                font-weight: bold;
                margin-top: 15px;
                 @media (min-width :$screen-lg-min) {
                    font-size: 22px;
                }

                

            }

        }

        .btn {
            font-weight: bold;
            background-color: rgb(72, 134, 255);
            border-color: rgb(72, 134, 255);
            border-radius: 4px;
            @media (min-width: $screen-md-min) {
                padding: 12px 50px;
            }
            &:hover, &:focus {
                background-color: #fff !important;
                border-color: rgb(72, 134, 255) !important;
                color: rgb(72, 134, 255) !important;
            }
        }

       

        

    }
    .owl-dots {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
    }

    .owl-item .slide .slide-text h2{
        opacity: 0;
        transform: translate(50%, 0);
    }


    .owl-item.active .slide .slide-text h2{
        opacity: 1;
        transform: translate(0, 0);
        @include transition(all 500ms ease 0.8s);
    }

    .owl-item .slide .slide-text .btn{
        opacity: 0;
        transform: translate(-100%, 0);
    }


    .owl-item.active .slide .slide-text .btn{
        opacity: 1;
        transform: translate(0, 0);
        @include transition(all 500ms ease 0.8s);
    }


}


// Section colors
.blau {
    background-color: #ebf2f6 !important;
}

.gris {
    background-color: #fff !important;
    background-image: url('../img/body-bg.jpg') !important;
    background-position: top left !important;
    background-size: cover !important;
}

.blanc {
    background-color: #fff !important;
}


// Section title
.sectiontitle {
    width: 100%;
    float: left;
    text-align: center;
    h2 {
        text-transform: uppercase;
        margin: 90px auto 50px auto;
        font-family: $title-font;
        font-weight: bold;
    }
}

.sectiontext {
    text-align: center;
}



// Propuesta
.propuesta {
    margin: 30px 0;
    
    .image {
        margin: 0 0 15px 0;
        width: 50%;
        height: auto;
    }
    .text {
       
     
    }

}



// Links
.more {
    &:after {
        font-family: 'FontAwesome';
        content: '\f105';
        padding-left: 7px;
     

    }
    &:hover, &:focus {
        text-decoration: none;
        color: $color-3;
    }
}



// Button CTA
.featured_block {
    position: relative;
    display: table;
    overflow: hidden;
    margin: 0;
    width: 100%;
    border: 1px solid #e0e0e0;
}

.featured_content {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: #fcfcfc;
    vertical-align: middle;
    padding: 0 30px;

    @include MQ(SM) {
        display: table-cell;
    }

    h2 {
        margin: 30px auto;
        font-weight: lighter;
        font-size: 22px;
        line-height: 1.5;
    }

}

.featured_button {
    position: relative;
    display: block;
    border-left: 1px solid #e0e0e0;
    background-color: #ffffff;
    vertical-align: middle;
    padding: 50px 30px;
    text-align: center;

    @include MQ(SM) {
        display: table-cell;
    }

}

.featured_button:before {
    position: absolute;
    top: 50%;
    left: -8px;
    margin-top: -3px;
    border-top: 5px solid transparent;
    border-right: 7px solid #e0e0e0;
    border-bottom: 5px solid transparent;
    content: '';
}

.featured_button:after {
    position: absolute;
    top: 50%;
    left: -6px;
    margin-top: -3px;
    border-top: 5px solid transparent;
    border-right: 7px solid #ffffff;
    border-bottom: 5px solid transparent;
    content: '';
}


// cta-bg-image
.cta-bg-image {
    padding: 80px 0 100px 0;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
    text-align: center;
    color: #fff;
}

// Testimonis
.testimonis {
    margin: 0 0 80px 0;
    .testimoni {
        @include flexbox;
        @include flex-wrap($value: wrap);
        @include align-items($value: center);
        @include align-content($value: center);
        @include justify-content($value: flex-start);
        .image {
            width: 100%;
            @include MQ(SM) {
                width: 50%;
            }
            .cover {
                width: 100%;
                height: 0;
                min-height: 0;
                padding-bottom: 56.2%;
                background-size: cover;
                background-position: center center;
            }
        }
        .text {
            width: 100%;
            padding: 30px;
            position: relative;
            text-align: center;
            @include MQ(SM) {
                width: 50%;
                padding: 0 30px;
            }
            span.fa {
                text-align: center;
                font-size: 30px;
                line-height: 1;
            }
            p {
                font-style: italic;
                font-family: Georgia,serif;
                span {
                    display: block;
                    font-style: normal;
                    font-weight: bold;
                }
            }
          
        }
    }
}









// Servicios
.servicios {
    width: 100%;
    float: left;
    margin: 60px auto;
}
/* Style the tab */
.tab {
    float: left;
    width: 100%;
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: center);
    @include align-content($value: center);
    @include justify-content($value: flex-start);
}

/* Style the buttons inside the tab */
.tab a {
    width: 33.3333%;
    display: block;
    background-color: inherit;
    color: #999999;
    padding: 20px 15px;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    border: 1px solid #eee;
}

/* Change background color of buttons on hover */
.tab a:hover {
    color: #000;
    text-decoration: none;
}

/* Create an active/current "tab button" class */
.tab a.active {
   color: #000;
}

/* Style the tab content */
.tabcontent {
    float: left;
    padding: 30px 0px;
    //border: 1px solid #eee;
    width: 100%;
    border-left: none;
    img {
        margin: 15px 0;
    }
    q {
        margin: 30px auto;
        color: #999999;
        &:after, &:before {
            content: '';
        }
        span {
            display: block;
            margin-top: 10px;
            //color: $main-text;
            font-style: normal;
            font-weight: bold;
        }
    }
    ul {
        list-style: disc;
        margin-left: 30px;
    }
}




.header-space {

    @include MQ(SM) {
        width: 100%;
        float: left;
        height: 80px;
        background-color: #231F20;
        
    }
}

// Imatge principal
.img-principal {
    width: 100%;
    float: left;
    height:  300px;
    position: relative;
    background-size: cover;
    background-position: center center;
    @include MQ(SM) {
        height:  0;
        min-height: 0;
        padding-bottom: 30.07752%;
    }
    .img-gran {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: table;
        text-align: center;
        width: 100%;
        height: 100%;
        color: #fff;
        h2 {
            display: table-cell;
            vertical-align: middle;
            font-family: $text-font;
            font-weight: 900;
            font-size: 50px;
            padding: 0 0 50px 0;
            @include MQ(SM) {
                font-size: 60px;
            }
            @include MQ(M) {
                font-size: 90px;
            }
            @include MQ(L) {
                font-size: 110px;
            }
        }
    }
}





.bg-video-full {
    margin: 0;
    width: 100% !important;
    float: left;
    overflow: hidden;
    height: 0;
    min-height: 0;
    padding-bottom: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @media(min-width:$screen-sm-min) {
        padding-bottom: 56.25%;
    }

    //position: relative;
    //z-index: 999;
    .video-full {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: none;
        @media(min-width:$screen-sm-min) {
            display: block;
        }
    }


}







// imatge videos
.video-img {
    margin: 15px 0;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    min-height: 0;
    width: 100%;
    background-color: #000;
    .play {
        @include center($xy:xy) ;
        width: 60px;
        height: 60px;
        background-color: transparent;
        background-image: url('../img/play.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        position: absolute;
        text-align: center;
        z-index: 30;
    }
    img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        cursor: pointer;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
    }
}




// logoss-add
.logos {
    
    margin: 60px 30px;
    padding: 0;
    width: calc(100% - 60px);
    
    .owl-stage {
        @include flexbox;
        @include flex-wrap($value: wrap);
        @include align-items($value: center);
        @include align-content($value: center);
        @include justify-content($value: center);
    }
    .logo {
        margin: 0;
        //width: 50%;
        padding: 0;
        height: auto;
        text-align: center;
        font-size: 14px;
        @include MQ(M) {
            //width: 25%;
        }
        a {
            color: $main-text;

            &:hover, &:focus {
                text-decoration: none;
                color: $link;
            }

        }

        img {
            max-height: 80px;
            width: auto;
            margin: 0 auto;
            filter: grayscale(0);
    
            transition: all .6s ease;
            opacity: 1;
            &:hover, &:focus {
                opacity: 1;
                filter: grayscale(100%);
            }
        }

        p {
            margin: 10px 0;
        }

    }

}



// logoss-add
.column-logos {
    
    margin: 30px auto;
    padding: 0;
    width: 100%;
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: center);
    @include align-content($value: center);
    @include justify-content($value: center);
    
    .owl-stage {
        
    }
    .logo {
        margin: 0;
        width: 25%;
        padding: 2px 4px;
        height: auto;
        text-align: center;
        font-size: 14px;
        @include MQ(M) {
            //width: 25%;
        }
        a {
            color: $main-text;

            &:hover, &:focus {
                text-decoration: none;
                color: $link;
            }

        }

        img {
            height: auto;
            width: 100%;
            margin: 0 auto;
            filter: grayscale(0);
    
            transition: all .6s ease;
            opacity: 1;
            padding: 10px;
            background: #fff;
            border-radius: 5px;
            &:hover, &:focus {
                opacity: 1;
                filter: grayscale(100%);
            }
        }

        p {
            margin: 10px 0;
        }

    }

}






// slide proyectos
.slide-proyectos {
    margin: 0 auto 60px auto;
    clear: both;
    width: calc(100% - 60px);
    .owl-stage {
     
    }
    .owl-dots {
        bottom: -20px;
    }
    .owl-nav .owl-prev {
        left: -50px;
    }
    .owl-nav .owl-next {
        right: -50px;
    }

}

// Archive proyecto
.archive-proyectos {
    margin: 0 -15px;
    clear: both;
   
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: stretch);
    @include align-content($value: flex-start);
    @include justify-content($value: flex-start);
    .proyecto {
        width: 100%;
        padding: 15px;
        position: relative;
        @include MQ(SM) {
            width: 50%;
        }
        @include MQ(L) {
            width: 33.3333%;
        }
      
    }
}


// Proyecto block
.proyecto {
    text-align: center;
    margin: 0;
    width: 100%;
    float: left;

    a {
     
        &:hover, &:focus {
            text-decoration: none;

        }

    }

    .image {
        height: 0;
        min-height: 0;
        padding-bottom: 56.25%;
        position: relative;
        overflow: hidden;
        width: 100%;
        float: left;
        background-color: $color-4;
    }


    .cover {
        height: 0;
        min-height: 0;
        padding-bottom: 56.25%;
        background-position: center center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        @include transition(all linear .2s);
    }

    .text {
        width: 100%;
        float: left;
        padding: 0;
        text-align: left;
        @include transition(all linear .2s);

        h3 {
            margin: 30px auto 10px auto;
            font-weight: bold;
            @include transition(all linear 0s);
            font-size: 20px;
           
        }

    }

}






// Section title
.breadcrumb {
    padding: 8px 15px;
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    font-size: 10px;
    @include MQ(SM) {
        font-size: 14px;
    }
}

.breadcrumb > .active {
    color: #eee;
}

.section-title-breadcrumb {
    display: table;
    width: 100%;
    color: $white;
    
    margin-bottom: 0;
    overflow-y: hidden;
    background-size: cover;
    background-position: center center;
    
    .cover {
        padding-top: 100px;
        
 
        background: linear-gradient(
            90deg, 
            rgba($color-2,0.8) 0%, 
            rgba($color-2,0.3) 30%, 
            rgba($color-2,0.3) 70%, 
            rgba($color-2,0.8) 100%
        );

      


        @include MQ(M) {
            padding-top: 130px;
        }

        @include MQ(L) {
            padding-top: 175px;
        }
    }

    .container {
        position: relative;
        height: auto;
        padding-top: 30px;

        @include MQ(S) {
            height: auto;
        }

        @include MQ(L) {
            height: auto;
        }

    }

    .breadcrumb-title {
        color: $white;

        a {
            color: $white;

            &:hover, &:focus {
                text-decoration: underline;
            }

        }

    }

}


// Equipo
.equipo-old {
    margin: 15px auto;
    padding: 0;
    width: 100%;
    background: #fff;
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: stretch);
    @include align-content($value: flex-start);
    @include justify-content($value: flex-start);
    .image {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-size: cover;
        background-position: top center;
        background-color: #eee;
        @include MQ(L) {
            width: 50%;
            padding-bottom: 100%;
        }
    }
    .text {
        padding: 20px;
        width: 100%;
        @include MQ(L) {
            width: 50%;
        }
    }
}



.equipo {
    margin: 60px auto 30px auto;
    padding: 0;
    width: 100%;
    
    
    position: relative;
    .image {
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: top center;
        background-color: #eee;
        margin: 0;
        border-radius: 50%;
        border: 5px solid #fff;
        position: absolute;
        top: -50px;
        right: -15px;
     
    }
    .text {
        padding: 10px 20px 20px 20px;
        width: 100%;
        font-size: 14px;
        background: #fff;
        border-radius: 5px;
        min-height: 320px;
        @include MQ(L) {
            
        }
        p {
            margin: 0 5px 0 0;
        }
        ol li, ul li {
            padding: 0;
        }
    }
}




// Last news
.last-news {
    margin: 0 auto 90px auto;
    clear: both;
    width: calc(100% - 60px);
    clear: both;
   
    .owl-stage {
        @include flexbox;
        @include flex-direction($value: row);
        @include align-items($value: stretch);
        padding: 5px 0;
    }
    .owl-dots {
        bottom: -60px;
    }
    .owl-nav .owl-prev {
        left: -50px;
    }
    .owl-nav .owl-next {
        right: -50px;
    }

    .post {
        text-align: center;
       
        margin: 0;
        height: 100%;
        background-color: #fff;

        a {
            //@include flexbox;
            //@include flex-direction($value: column);
            height: 100%;

            &:hover, &:focus {
                text-decoration: none;

                .text {
                   
                   color: #fff;
                   background: $theme-secondary;
                   h3 {
                        color: #fff;
                   }
                    
                }
                .fecha {
                    color: #fff;
                }

                .cover {
                    //transform: scale(1.1);
                    //background-size: 110%;
                }

            }

        }

        .image {
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            position: relative;
            overflow: hidden;
            width: 100%;
            float: left;
            background-color: #f9f9f9;
        }

        .fecha {
            text-transform: uppercase;
            color: #A6A8AB;
            margin: 0;
            font-family: $title-font;
            @include transition(all linear .1s);
        }

        .cover {
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            background-position: center center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @include transition(all linear .2s);
        }

        .text {
            width: 100%;
            float: left;
            min-height: 175px;
            height: 100%;
            padding: 10px 15px 30px 15px;
            background: #fff;
            color: $black;
            text-align: left;
            @include transition(all linear .2s);

            h3 {
                margin: 10px auto 10px auto;
                color: $theme-primary;
                text-transform: uppercase;
                font-weight: bold;
                @include transition(all linear 0s);
                font-size: 20px;
                @include MQ(L) {
                    font-size: 20px;
                }
            }

        }

    }

}


.view-all {
    width: 100%;
    margin: 30px auto 60px auto;
    text-align: center;
    
}



.archive-blog {
    margin: 0 -15px;
    clear: both;
   
    @include flexbox;
    @include flex-wrap($value: wrap);
    @include align-items($value: stretch);
    @include align-content($value: flex-start);
    @include justify-content($value: flex-start);
    .post-cover {
        width: 100%;
        padding: 15px;
        position: relative;
        @include MQ(SM) {
            width: 50%;
        }
        @include MQ(L) {
            width: 33.3333%;
        }
      
    }




    .post {
        text-align: center;
       
        margin: 0;
        
        background-color: #fff;
        
        position: relative;
       

        a {
            //@include flexbox;
            //@include flex-direction($value: column);
            height: 100%;

            &:hover, &:focus {
                text-decoration: none;

                .text {
                   
                   color: #fff;
                   background: $theme-secondary;
                   h3 {
                        color: #fff;
                   }
                    
                }
                .fecha {
                    color: #fff;
                }

                .cover {
                    //transform: scale(1.1);
                    //background-size: 110%;
                }

            }

        }

        .image {
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            position: relative;
            overflow: hidden;
            width: 100%;
            float: left;
            background-color: #f9f9f9;
        }

        .fecha {
            text-transform: uppercase;
            color: #A6A8AB;
            margin: 0;
            font-family: $title-font;
            @include transition(all linear .1s);
        }

        .cover {
            height: 0;
            min-height: 0;
            padding-bottom: 56.25%;
            background-position: center center;
            background-size: cover;
            position: relative;
            overflow: hidden;
            @include transition(all linear .2s);
        }

        .text {
            width: 100%;
            float: left;
            height: 100%;
            padding: 10px 15px 30px 15px;
            background: #fff;
       
            text-align: left;
            @include transition(all linear .2s);

            h3 {
                margin: 10px auto 10px auto;
                color: $theme-primary;
                text-transform: uppercase;
                font-weight: bold;
                @include transition(all linear 0s);
                font-size: 20px;
                @include MQ(L) {
                    font-size: 20px;
                }
            }

        }

    }

}


.single-p {
    // Post archive
    padding-top: 60px;
    margin: 0 auto;
    max-width: 800px;
    .post {
        width: 100%;
        float: left;
        margin: 0 0 60px 0;
        //background-color: $bg;
        a {
            &:hover, &:focus {
                text-decoration: none;
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
        .image {
            margin: 0;
            position: relative;
            width: 100%;
            float: left;
            img {
                width: 100%;
                height: auto;
            }
            .cover {
                height: 0;
                min-height: 0;
                padding-bottom: 56.2%;
                position: relative;
                background-size: cover;
                background-position: center center;
            }
            .fechas {
                position: absolute;
            }
        }
        .text {
            width: 100%;
            float: left;
            .post-title  {
                margin: 15px 0;
            }
            .categories {
                a {
                    margin-right: 10px;
                    font-weight: bold;
                }
            }
            ul {
                list-style: disc;
                margin-left: 30px;
            }
        }
        .fecha {
            text-transform: uppercase;
            color: #A6A8AB;
            margin: 10px 0;
            font-family: $title-font;
            @include transition(all linear .1s);
        }
        .embed-responsive {
            margin: 30px auto;
        }
    }

    .compartir {
        margin: 50px 0 15px 0;
        list-style: none;
        text-align: left;
        font-size: 40px;
        width: 100%;
        float: left;
        padding: 0;
        li {
            display: inline-block;
            margin-right: 0;
        }
    }



}

.alignleft {
    float: left;
    margin: 15px 0;
    width: 100%;
    height: auto;
    max-width: 300px;
    height: auto;
    @include MQ(SM) {
        width: 33.3333%;
        margin: 0 30px 15px 0;
    }
}
.alignright {
    float: right;
    margin: 15px 0 ;
    width: 100%;
    height: auto;
    max-width: 300px;
    height: auto;
    @include MQ(SM) {
        width: 33.3333%;
        margin: 0 0 15px 30px;
    }
}


.wp-caption-text {
    font-size: 12px;
}

// Map
.map-item {
    width: 100%;
    height: 400px;
    float: left;
    margin: 0;
    @include MQ(L) {
        height: 500px;
    }
}




// Formulari (necessita js i function)

.formulari {
    margin: 30px auto;
     width: 100%;
    float: left;
    .wpcf7-submit {
        float: right;
        margin: 10px 0;

    }

}

span.wpcf7-list-item {
    margin: 0 0 0 0 !important;
    input[type="radio"], input[type="checkbox"] {
        margin: 0 5px 0 0;
    }
}




.g-recaptcha {
    margin: 0 0 30px 0;
}
@media screen and (max-width: 320px){
    .g-recaptcha {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}



.form-group {
    width: 100%;
    float: left;
    margin: 0;
    p {
        display: block;
        float: left;
    }

    label {
      
        color: $main-text;
        margin: 10px 0;
    }

    :focus {
        outline: none;
    }


}



.form-control {
    @include border-radius(0);
    //color: $gris-clar;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 10px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.075);
    color: $main-text;
    font-size: 16px;
    //@include transition(all linear .2s);
    position: relative;

    &:hover, &:focus {
        //border: 1px solid $theme-primary;
        color: $main-text;
        outline: none;
    }

    &:focus {

        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &::-webkit-input-placeholder {
        //color: $theme-primary;
    }

    &:-moz-placeholder {

        /* Firefox 18- */
        //color: $theme-primary;
    }

    &::-moz-placeholder {

        /* Firefox 19+ */
        //color: $theme-primary;
    }

    &:-ms-input-placeholder {
        //color: $theme-primary;
    }

}

textarea {
    @include border-radius(0);
    //color: $gris-clar;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 5px 10px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.075);
    color: $main-text;
    @include transition(all linear .2s);
    width: 100%;
    font-size: 16px;
    
    position: relative;
    min-height: 200px;
    &:hover, &:focus {
        //border: 1px solid $theme-primary;
        color: $main-text;
        outline: none;
    }
}

/*= input focus effects css
=========================== */
:focus {
    outline: none;
}

/* necessary to give position: relative to parent. */
.magia {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 15px;
    z-index: 100;
    background: $white;
}



.effect-11 {
    border: 1px solid $color-1 !important;
    position: relative !important;
    background: transparent !important;
    z-index: 11 !important;
}

.error .effect-11 {
    border: 1px solid red !important;
}


.effect-11 ~ .focus-bg {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 0 !important;
    height: 100% !important;
    background-color: $background !important;
    transition: 0.3s !important;
    z-index: 8 !important;
}

.effect-11:focus ~ .focus-bg {
    transition: 0.3s !important;
    width: 100% !important;
}



div.wpcf7-response-output {
    margin: 0 !important;
    padding: 15px !important;
    border: 0 !important;
}

span.wpcf7-not-valid-tip {
    background-color: transparent !important;
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 10px;
}


.invalid .wpcf7-validates-as-required {
    border: 1px solid red !important;
}

.invalid .wpcf7-validates-as-required.has-content {
    border: 1px solid green !important;
}




// recaptcha
body:not(.page-id-15) .grecaptcha-badge {
    display: none;
}



// Popup
.popup-block {
    background: transparent;
    color: #fff;
    padding: 30px 15px 15px 15px;
    text-align: left;
    max-width: 1200px;
    margin: 40px auto;
    position: relative;
   

    h3 {
        margin-top: 0;
        font-family: $title-font;
        font-weight: bold;
        font-size: 18px;
        @include MQ(SM) {
            font-size: 22px;
        }
        @include MQ(M) {
            font-size: 28px;
        }
        @include MQ(L) {
            font-size: 38px;
        }
    }
    .mfp-close {
        color: #fff !important;
        right: 0px;
        top: -10px;
    }
    .custom-post {
        @include flexbox;
        @include flex-direction($value: row);
        @include flex-wrap($value: wrap) ;
    }

    .foto {
        width: 100%;
        @include MQ(SM) {
            width: 66.6666666667%;
        }

    }
    .text {
        width: 100%;
        background-color: #483D4A;
        padding: 15px;
        @include MQ(SM) {
            width: 33.333333%;
        }
        .fa {
            width: 20px;
        }
        ul {
            list-style: none;
            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}

.popup-block-post {
    background: #E7ECEC;
 
    padding: 30px 15px 15px 15px;
    text-align: left;
    max-width: 1200px;
    margin: 40px auto;
    position: relative;
   

    h3 {
        margin-top: 0;
        font-family: $title-font;
        font-weight: bold;
        font-size: 16px;
        @include MQ(SM) {
            font-size: 18px;
        }
        @include MQ(M) {
            font-size: 22px;
        }
        @include MQ(L) {
            font-size: 28px;
        }
    }
    .mfp-close {
        color: #000 !important;
        right: 0px;
        top: 0;
    }
    .custom-post {
        @include flexbox;
        @include flex-direction($value: row);
        @include flex-wrap($value: wrap) ;
    }

    .foto {
        width: 100%;
        @include MQ(SM) {
            width: 50%;
        }

    }
    .text {
        width: 100%;
        background-color: transparent;
        padding: 0 15px 0 30px;
        @include MQ(SM) {
            width: 50%;
        }
        .fa {
            width: 20px;
        }
        ul {
            list-style: none;
            li {
                padding: 0;
                margin: 0;
            }
        }
    }
}





// footer
.prefooter {
    width: 100%;
    float: left;
    background-color: $color-1;
    color: $white;
    text-align: left;
    a {
        color: $white;
    }
}


// Contacte
.contacte {
 
    position: relative;

    width: 100%;
    float: left;
    margin: 15px auto;
    @include flexbox;
    @include align-items($value: flex-start);
    @include flex-direction($value: column);
    @include justify-content($value: flex-start);
    @include flex-wrap($value: wrap);
    @include MQ(SM) {
        @include flex-direction($value: row);
    }
    .widget {
        margin: 15px 0;
        width: 100%;
        padding: 15px;
        @include MQ(SM) {
            width: 33.3333%;
        }
        @include MQ(L) {
           
        }   
        @include MQ(XL) {
            
        }
        h3 {
            margin-top: 10px;
            
            margin-bottom: 15px;

        }
        p {
           
           

        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                a {
                    color: #fff;
                    display: block;
                    font-weight: bold;
                    line-height: 1.2;
                    padding: 5px;
                    border-bottom: 1px solid rgba(255,255,255,0.5);
                    &:hover, &:focus {
                        text-decoration: none;
                        color: $color-4;
                    }
                    &:before {
                        content: "\f054";
                        font-family: 'FontAwesome';
                        padding-right: 5px;
                        font-size: 8px;
                        line-height: 19px;
                    }

                }
                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    .text-2 {
        @include MQ(L) {
            width: 50%;
        }
        

    }
    .nav_menu-2 {
        @include MQ(L) {
            width: 25%;
        }
        
    }
    .nav_menu-3 {
        @include MQ(L) {
            width: 25%;
        }
        
    }
    
}



.footer {
    width: 100%;
    float: left;
    margin: 0;
    background-color: $color-2;
    color: #fff;
    font-size: 10px;
    text-align: center;
    @include MQ(SM) {
        font-size: 12px;
        text-align: left;
    }
    p {
        margin: 30px auto 0 auto;
        display: inline-block;
        
        @include MQ(SM) {
            margin: 15px 0;
            float: left;
        }
        a {
            color: #fff;
            &:hover, &:focus {
                text-decoration: underline;
                color: #fff;
            }
        }
    }

    .social {
        
        margin: 10px auto 20px auto;
        list-style: none;
        display: inline-block;
        @include MQ(SM) {
            margin: 10px 0 0 0;
            float: right;
        }
        li {
            padding: 0 0 0 15px;
            display: inline-block;
            float: left;
            margin: 0;
            a {
                color: #fff;
                &:hover, &:focus {
                    text-decoration: none;
                    color: $color-4;
                }
                span {
                    font-size: 25px;
                    line-height: 40px;
                }
            }
        }
    }
}



.video-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; }
.video-container iframe, .video-container object, .video-container embed, .video-container video { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }



.error-404 {
    text-align: center;
    width: 100%;
    height: 100vh;
    min-height: 100%;

    @include flexbox;
    @include align-items($value: center);
    background: #e8dcd2 url('../img/404.jpg') center center no-repeat !important;
    background-size: cover !important;


}

.error-404 .message {
    margin: 0 auto 15px auto;
}

.error-404 h1 {
    font-weight: normal;
   
    margin: 0;
    color: #fff;
    line-height: 1.3;
    font-size: 16px;
    font-weight: bold;

}

@media (min-width:768px) {
    .error-404 h1 {
        font-size: 36px;
    }
}
@media (min-width:1024px) {
    .error4-04 h1 {
        font-size: 42px;
    }
}

.error-404 h1 a {
    color: $theme-primary;
    text-decoration: underline;
}

.error-404 .footer {
    display: none;
}



.wp-pagenavi {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center; 
    position: relative;
    padding:0;
    margin: 2rem 0 0 0;
    color: #333;
    clear: both;
    line-height: 1;
    a {
        color: #333;
        padding: 0.5em;
        //border-right: 1px solid  #d3ced2;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $link;
        }
    }

    span {
        &:after, &:before {
            //vertical-align: middle;
        }
    }

    
    .current {
        padding: 0.5em;
        background: #ebe9eb;
        color: #8a7e88;
    }

   

}




/* Cookie bar */

#cookie-law-info-bar {
  padding: 30px 0 !important;
  border-top: 3px solid $link !important;
  background-color: #eee;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999; 
}

#cookie-law-info-bar p {
  margin: 10px;
  display: block;
  clear: both;
  font-size: 14px; 
}

@media (min-width: 768px) {
 .cookies {
    display: flex;
    align-items: center; } 
   #cookie-law-info-bar p {
    font-size: 16px;
   }

}


.cookies .cookies-close {
  padding: 20px; 
}
 .cookies .cookies-close a {
    padding: 6px 15px;
    border: 2px solid $link;
    color: $link !important;
    background: #fff !important;
    margin: 0 auto !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    display: inline-block; 
}
 .cookies .cookies-close a:hover, .cookies .cookies-close a:focus {
      text-decoration: none;
      background: $link !important;
      color: #fff !important; 
  }

#cookie-law-info-bar .cli-plugin-main-link {
  margin: 0; }
