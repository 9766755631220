@import url('https://fonts.googleapis.com/css?family=Heebo:400,500,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,500,500i,700,700i');


$font-family-sans-serif:  'Heebo', sans-serif;
$font-family-serif: 'Ubuntu', sans-serif;

$text-font:  'Heebo', sans-serif;
$title-font: 'Ubuntu', sans-serif;
$button-font: 'Ubuntu', sans-serif;

// fonts 
$primary-font: $text-font;
$secondary-font: $title-font;


$color-1: #336699; // shadow
$color-2: #2a3132; // autum
$color-3: #743624; // stone
$color-4: #90afc5; // mist
$color-5: #ffffff; // white

$main-text: #2a3132; // main text
$link: $color-1; // anchor tags
$background: #f8f8f8; // body background color



$black: $color-1;
$white: $color-5;
$bg: $color-4;
$title: $color-2;


// bootstrap
$brand-primary: $color-1!default;
$theme-primary: $color-1;
$theme-secondary: $color-2;



   
$S:     480px;   
$SM:    768px;  
$M:     992px;     
$L:     1200px;     
$XL:     1800px;  

// media queries
@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @if $canvas == SM {
   @media only screen and (min-width: $SM) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
  @else if $canvas == XL {
   @media only screen and (min-width: $XL) { @content; } 
  }
}




